<template>
             <!-- tournament details banner section start -->
            <div class="tournament-details pb-10">
                <div class="container">
                    <div class="row mb-lg-10 mb-6">
                        <div class="col-12">
                            <div class="d-flex align-items-center gap-4">
                                <a href="javascript:void(0)" onclick="window.history.back()" class="back-btn"><i
                                        class="ti ti-arrow-narrow-left fs-2xl"></i></a>
                                <h3 class="tcn-1 cursor-scale growDown title-anim">{{ t.details.back }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="parallax-banner-area parallax-container position-relative rounded-5 overflow-hidden">
                                <img class="w-100 h-100 parallax-img" src="../../assets/image/tour-details-banner.png"
                                    alt="">
                                <!-- running tournament content here -->
                                <div class="running-tournament d-flex flex-lg-row flex-column position-absolute top-50 start-50 translate-middle w-100" v-for="game in gamesDetails" :key="game.id">
                                    <div class="running-tournament-thumb w-100">
                                        <img class="w-100 h-100" v-lazy="game.thumbnailUrl"
                                            alt="">
                                    </div>
                                    <div class="running-tour-info py-xl-10 py-sm-6 py-4 px-xl-15 px-lg-10 px-sm-6 px-4 w-100" style="padding-top: 4.5rem !important;">
                                        
                                        <template v-if="discriptionOne">
                                          <h3 class="tcn-1 mb-lg-6 mb-4">{{ game.title_en }}</h3>
                                        </template>
                                        <template v-if="discriptionTow">
                                          <h3 class="tcn-1 mb-lg-6 mb-4">{{ game.title_ar }}</h3>
                                        </template>
                                        <template v-if="discriptionThree">
                                          <h3 class="tcn-1 mb-lg-6 mb-4">{{ game.title_en }}</h3>
                                        </template>

                                        <template v-if="discriptionOne">
                                          <span class="tcn-1 d-block fs-five fw-semibold mb-4" style="font-size: 16px !important;line-height: 160% !important;">{{ game.discription_en }}</span>
                                        </template>
                                        <template v-if="discriptionTow">
                                          <span class="tcn-1 d-block fs-five fw-semibold mb-4" style="font-size: 16px !important;line-height: 160% !important;">{{ game.discription_ar }}</span>
                                        </template>
                                        <template v-if="discriptionThree">
                                          <span class="tcn-1 d-block fs-five fw-semibold mb-4" style="font-size: 16px !important;line-height: 160% !important;">{{ game.discription_en }}</span>
                                        </template>
                                        <div class="d-flex align-items-center gap-sm-6 gap-3">
                                            <a @click="getFeed(game)"
                                                class="btn-half-border position-relative d-inline-block py-2 bgp-1 px-6 rounded-pill"> {{ t.tournament.playnow }}
                                                </a>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- tournament details banner section end -->
             <app-suggestions/>
</template>

<script>
import { onMounted, ref } from 'vue';
import { HTTP } from '@/Api/http-common';
import { useRouter } from "vue-router";
import { useCookie } from 'vue-cookie-next';
import AppSuggestions from './AppSuggestions.vue';
import { t, availableLanguages, setLanguage, currentLanguageCode } from '../../locales/language'
export default {
  components: { AppSuggestions },
    name: "AppDetails",
    props: {
        gid: String
    },
    mounted() {
    const s = document.createElement('script');
        s.type = 'text/javascript';
        s. src = '/include/js/main.js';
        document.body.appendChild(s);
   },
    async setup(props) {
      const router = useRouter();
      //   const route = useRoute();
      const cookie = useCookie();
      const gamesDetails = ref([]);
      const discriptionOne = ref(false)
      const discriptionTow = ref(false)
      const discriptionThree = ref(false)


      onMounted(() => {
          if (localStorage.getItem('language') === '') {
            discriptionOne.value =  true;
          } else if (localStorage.getItem('language') === 'AR') {
            discriptionTow.value = true;
          } else {
            discriptionThree.value = true;
          }
      })

      try {
        await HTTP.get(`GamesDetailsById.php?id=`+props.gid).then((res) => {
          gamesDetails.value = res.data.GamesDetails; 
        });
      } catch (err) {
        console.log(err);
      }

      const getFeed = (game) => {

        cookie.setCookie('cat', game.category)
        
        try {
              HTTP.post(`GamesClicks.php?gID=${game.id}`).then((res) => {
                  if (res.data.status === 1) {
                    // console.log("")
                  } else {
                    // console.log("not insert")
                  }
              }).catch((error) => {
                  console.log(error);
              });
          } catch (err) {
              console.log(err);
          }
          router.push({ name: "PlayGames", params: { gid: game.gid } });
      };

      return {
        getFeed,
        gamesDetails,
        t, availableLanguages, setLanguage, currentLanguageCode, discriptionOne, discriptionTow, discriptionThree
      };

    }
}
</script>

<style>

</style>