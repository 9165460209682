<template>
    <app-header />
    <main class="main-container container-fluid d-flex pt-sm-20 pt-15 px-0 position-relative">
        <app-sidebar/>
        <article class="main-content mt-lg-10 mt-6">
            <Suspense>
                <template #default>
                    <app-details :gid='gid'/>
                </template>
                <template #fallback>
                    <app-loading />
                </template>
            </Suspense>
            <app-footer />
        </article>
    </main>
</template>

<script>
import AppHeader from '@/components/layouts/AppHeader.vue'
import AppSidebar from '@/components/layouts/AppSidebar.vue'
import AppDetails from '@/components/Pages/AppDetails.vue'
import AppFooter from '@/components/layouts/AppFooter.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from "vue";
export default {
  components: { AppHeader, AppSidebar, AppDetails, AppFooter, AppLoading, Suspense },
   name: "Details",
   props: {
    gid: String
  },
}
</script>

<style>

</style>